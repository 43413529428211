import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import './App.css';
import IccsExpress from './basicFrame';

function App() {
  return (
    <div className="App">
      <React.Fragment>
        <CssBaseline />
        <IccsExpress/>
      </React.Fragment>
    </div>
  );
}

export default App;
